export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Actions',
    route: 'actions',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Insights',
    route: 'insights',
    icon: 'MapIcon',
  },
  {
    title: 'Manage Users',
    route: 'manage-users',
    icon: 'UserIcon',
  },
  {
    title: 'Take Survey',
    route: 'survey',
    icon: 'ClipboardIcon',
  },
]
